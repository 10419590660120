import { FormikProps } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CommonDialog } from "../../components/CommonDialog";
import { createAuction, getAuction, IAuction, updateAuction } from "../../services/auction-service";
import { AuctionForm } from "./AuctionForm";
interface TruckFormProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  open?: boolean;
}
export const AuctionDialog: React.FC<TruckFormProps> = ({
  handleToggleOpen,
  submitCallback,
}) => {
  
  const formRef = useRef<FormikProps<any>>(null);
  const navigate = useNavigate();
  const { auctionId } = useParams();
  const [containerData, setContainerData] = useState<IAuction>();
  const [errors, setErrors] = useState<any>();
  const [openUserForm, setOpenUserForm] = useState(false);
  const loadUserData = useCallback(async () => {
    if (auctionId) {
      const { data } = await getAuction(auctionId);
      setContainerData(data)
      setOpenUserForm(true)
    }
  }, [auctionId]);
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const handleSubmit = async (values: any) => {
    if(!auctionId)
    toast.promise(createAuction(values), {
      loading: "Loading...",
      success: (res) => {
        if(submitCallback) 
        submitCallback();
        navigate("/auctions");
        return `Successfully created auction ${res.data.name}`;
      },
      error: ({response}) => {
        setErrors(response.data?.message);
        return "Error when creating auction";
      }
    });
    else {
      toast.promise(updateAuction(auctionId, values), {
        loading: "Loading...",
        success: (res) => {
          if(submitCallback) 
          submitCallback();
          navigate("/auctions");
          return `Successfully updated auction ${res.data.name}`;
        },
        error: (res) => {
          return "Error when updating auction";
        }
      });
    }
  };
  useEffect(() => {
    if (auctionId) {
        loadUserData()
    } else {
        setOpenUserForm(true);
    }
    
  }, [auctionId, loadUserData]);

  return (
    <CommonDialog
      title={auctionId ? "Edit auction" : "Add auction"}
      open
      handleSubmit={handleFormSubmit}
      handleToggleOpen={handleToggleOpen}
    >
      {openUserForm && <AuctionForm containerData={containerData} handleToggleOpen={handleToggleOpen} formRef={formRef} handleSubmit={handleSubmit} />}
    </CommonDialog>
  );
};
