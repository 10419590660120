export const Header = (props: any) => {

    return (
        <>
        {/* <HeaderContainer>
        <Grid justifyContent="space-between" container spacing={4}>
            <Grid item sm={12} md={6}>
                <DateContainer>{today.toLocaleDateString("en-US", options)}</DateContainer>
                <Greeting>{greetingText}</Greeting>
            </Grid>
            <Grid className='header-action-items' alignItems="center" style={{display: "flex"}} lg={4} item xs={12} md={6}>
                <Grid textAlign="right" style={{paddingRight: 10}} item>
                    <ProfileName>Tsvetan Mitev</ProfileName>
                    {user?.role === UserRoles.ADMIN && <ProfileRole>Manager</ProfileRole>}
                </Grid>
                <Grid item justifyContent="center" display="flex" position="relative">
                    <MessageIcon onClick={handleOpenMessages} fontSize="large"  />
                    <MessageCounter>2</MessageCounter>
                </Grid>
                <Grid alignItems="center" style={{display: "flex"}}  item ><LocationOnIcon  fontSize="large"  /> Chateauguay</Grid>
                <Grid alignItems="center" item >
                </Grid>
            </Grid>
        </Grid>
        <Messages
        open={openMessages}
        onClose={closeMessages}
        />
      </HeaderContainer> */}
        </>

    );
};
