import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid/components";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { deleteAuction, getAuctions } from "../../services/auction-service";
import { GridActions } from "../../utils";
import { AuctionDialog } from "./AuctionDialog";
const ContainersActions = {
  ADD: "add-auction",
};
export const Auctions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [containers, setContainers] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [containerToDelete, setContainerToDelete] = useState<
    any | undefined
  >(undefined);
  const columns: GridColDef[] = [
    { field: "name", headerName: "Auction name", flex: 1 },
    {
      field: "trackingUrl",
      headerName: "Tracking url",
      flex: 1,
    },
    {
      field: "date",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams<Date>) => (
        <ActionMenu>
          <IconButton onClick={() => handleToggleOpenDetails(params.row)}>
            <EditIcon fontSize="medium" />
          </IconButton>
          <IconButton onClick={() => handleDeleteContainer(params.row)}>
            <DeleteForeverIcon color="error" fontSize="medium" />
          </IconButton>
        </ActionMenu>
      ),
    },
  ];
  const handleDeleteContainer = (container: any) => {
    setContainerToDelete(container);
    setDeleteOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (containerToDelete) await deleteAuction(containerToDelete.id);
      toast.success("Auction deleted successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
    loadAuctions();
    setContainerToDelete(undefined);
    setDeleteOpen(false);
  };
  const handleToggleOpenDetails = (auction?: any) => {
    navigate(
      !params.auctionId
        ? `/auctions/edit-auction/${auction?.id}`
        : "/auctions"
    );
  };
  const handleToggleOpen = () => {
    navigate(!params.action ? "/auctions/add-auction" : "/auctions");
  };
  const loadAuctions = async () => {
    const { data: auctionsData } = await getAuctions();
    setContainers(auctionsData.data);
  };
  useEffect(() => {
    loadAuctions();
  }, []);
  useEffect(() => {
    setOpen(params.action === ContainersActions.ADD || !!params.auctionId);
  }, [params.action, params.auctionId]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">Auctions</Typography>
        <Button variant="contained" onClick={handleToggleOpen}>
          Add auction
        </Button>
        {open && <AuctionDialog submitCallback={loadAuctions} handleToggleOpen={handleToggleOpen} />}
      </GridActions>
      <ConfirmDialog
        handleConfirm={handleConfirmDelete}
        handleClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        title="Are you sure to delete this auction?"
      >
        Deleting auction <strong>{containerToDelete?.name}</strong>
      </ConfirmDialog>
      <div style={{ height: "calc(100vh - 150px)", width: "100%" }}>
        <DataGrid
          onSelectionModelChange={(itm) => console.log(itm)}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableSelectionOnClick
          rows={containers}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5, 10, 20, 50]}
          //checkboxSelection
        />
      </div>
    </Box>
  );
};
